.slider {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    transition: transform 0.5s ease-out;
  } 
  
  .slider-item {
    flex: 0 0 auto;
    width: calc((100% - 3rem * 3) / 4);
    height: 100px;
    background-color: #ccc;
    text-align: center;
    line-height: 100px;
  }
  
  .filter-check-box{
    cursor: pointer;
  }
  @media (min-width: 991.98px){
    .setting-item{
      margin-left: 0 !important;
    }
  
  .icon-style {
    --fa-primary-color: #000000;
    --fa-secondary-color: #ffffff;
    position: absolute;
    top: 35%;
    left: -1%;
    background-color: white;
    color: black;
    font-size: 2em;
    cursor: pointer;
  }
}
/* ////////experience card ////////// */
.experience-card{
  min-height: 100%;
  max-height: 100%;
}
.card-title{
 max-width: 90% !important;
 overflow: hidden;
  }
.exp-card-container{
 /* min-width: max-content !important; */
}
.exp-background{
  width: 100%;
  max-width: 43vw;
}

.exp-background p{
  margin-bottom: 0px !important;
}
@media (min-width: 200px) and (max-width:370px) {
  .card-subject{
    height: 40px;
  }
  .card-title{

    min-height: 50px;
    max-height: 50px;
    }
    .exp-background{
      max-width: 73vw;
    }
}
@media (min-width: 370px) and (max-width:380px) {
  .card-title{
    
    min-height: 50px;
    max-height: 50px;
    }
    .exp-background{
      max-width: 77vw;
    }
}

@media (min-width: 380px) and (max-width:472px) {
  .card-title{
    
    min-height: 55px;
    max-height: 55px;
    }
    .exp-background{
      max-width: 77vw;
    }
}

@media (min-width: 472px) and (max-width:576px) {
  .card-title{
    
    min-height: 55px;
    max-height: 55px;
    }
    .exp-background{
      max-width: 82vw;
    }
}
@media (min-width: 576px) and (max-width:768px) {
  .card-title{
    
    min-height: 65px;
    max-height: 65px;
    }
    .exp-background{
      max-width: 85vw;
    }
  
}

@media (min-width: 768px) {
  .card-title{
    
    min-height: 65px;
    max-height: 65px;
    }
}

@media (min-width: 992px) and (max-width:1050px) {
  .card-subject{
    height: 60px;
  }
  .card-title{
    
    min-height: 85px;
    max-height: 85px;
    }
    
}

@media (min-width: 1050px) and (max-width:1200px) {
  .card-subject{
    height: 60px;
  }
  .card-title{
    
    min-height: 85px;
    max-height: 85px;
    }
}

@media (min-width: 1200px) and (max-width:1280px) {
  .card-subject{
    height: 60px;
  }
  .card-title{
    
    min-height: 85px;
    max-height: 85px;
    }
  
}

@media (min-width: 1280px) {
  .card-title{
    
    min-height: 88px;
    max-height: 88px;
    }
    .card-head{
      min-width: 210px;
    }
}

@media (min-width: 1380px) {
  .card-title{
    
    min-height: 88px;
    max-height: 88px;
    }
    .exp-background{
      max-width: 30vw;
    }
}


@media (min-width: 1749px) {
  .card-title{
    
    min-height: 85px;
    max-height: 85px;
    }
    .exp-background{
      max-width: 30vw;
    }
}



/* //////////add button//////////// */


@media(max-width:992px){
  .options-btn{
    margin-left:33px;
    height:32px !important;
    width:94px !important;
    padding-inline: 6px !important;
    border-radius: 10px !important; 
  }
  .custom-filter-btn{
    margin-left:15px;
    height:32px !important;
    width:150px !important;
    padding-inline: 6px !important;
    border-radius: 10px !important; 
    margin-left: 0;
  }
  .custom-filter-btn h3{
 
    font-size: 18px !important;
  }
  .options-btn img {
    width: 15px;
    height: 15px;
  }
  .filter-box .clearall{
    font-size:large;
  }
  .filter-box{
    z-index: 8 ;
  }
  .filter-position{
    z-index: 8;
  }
  .custom-filter-btn img{
    width: 20px;
    height: 20px;
  }
}
@media(max-width:575px){
  .custom-filter-btn{
    height:24px !important;
    margin-left: 0;
    width: 118px !important;
  }
  .filter-box .clearall{
    font-size:medium;
  }
  .custom-filter-btn h3{
    font-size: 14px !important;
  }
  .custom-filter-btn img{
    width: 12px;
  }
}

@media (min-width:992px) {
  .card-container-title {
   
    margin-bottom: -2px;
    height: 40px;
  }
}

/* /////////filter box /////////// */
.filter-box {
  max-width: fit-content !important;
  min-width: 185px !important;
}