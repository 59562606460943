/* color */

:root {
  /* Main Combination color variables  */
  --primary-color:255, 191, 163;
  --secondary-color: 128, 35, 118;
  --accent-caution-color: 255, 177, 61;
  --accent-dont-color: 188, 58, 31;
  --accent-do-color: 191, 194, 31;
  --shadow-color: 0px 0px 10px rgba(0, 0, 0, 0.24);
  --font-color: rgba(41, 41, 41, 1);
  --black-color: 0, 0, 0;
  --border-color: 229, 229, 229;
  --bg-white: 249, 238, 248;
  --white-color: 255, 255, 255;
  --link-color: rgba(128, 35, 118, 1);
  --disabled: rgb(201, 201, 201);
  
  --background-header-color: rgb(var(--primary-color-header));
 
}




/* typography */
h1,
.h1 {
  font-family: "Suez One";
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: var(--font-color);
}

h2,
.h2 {
  font-family: "Suez One";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.02em;
  color: var(--font-color);
}

h3,
.subtitle,
figcaption {
  font-family: "Syne";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: var(--font-color);
}

p,
li,
ul {
  font-family: "Syne";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: var(--font-color);
}


a,
a:hover {
  font-family: "Syne";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-decoration-line: none;
  color: var(--link-color);
}

span {
  color: var(--font-color);
}

cite {
  font-family: "Syne";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
}

.Main-Text:hover + .help {
  display: block;
  cursor: help;
  
}

.help {
  background-color: #FFFFFF;
  margin-top: 10px;
  width: 200px;
  border: 1px solid rgb(var(--secondary-color));
  padding: 10px;
  font-family: "Syne";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: none;
  
}


@media (max-width: 991.98px) {

.search-input{
  padding: 0rem 2rem !important;
}

  

  #faRight, #faLeft{
    transform: scale(1.35) !important;
}
  
  .options-btn{
    position: absolute !important;
    top: -0.1rem !important;
    left: 8.20rem !important;
  }

  .my-3{
    margin-top: 5rem !important;
  }


  .options-btn >  img{
    width: 20px;
    height: 20px;
  }
  h1,
  .h1 {
    font-weight: 400;
    font-size: 36px;
    line-height: 47px;
  }

  h2,
  .h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
  }

  h3,
  .subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }

  p,
  li,
  ul {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }


  a {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }

  cite {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }


}

@media (max-width: 575.98px) {


  .options-btn{
    transform: translateY(-55%) !important;
  }



  #faRight, #faLeft{
    transform: scale(0.80) !important;
}
  .my-3{
    margin-top: 3rem !important;
  }


  h1,
  .h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
  }

  h2,
  .h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  h3,
  .subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  p,
  li,
  ul {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }


  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  cite {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }


 
}

ol,
ul {
  padding-left: 68px;
  margin-bottom: 24px;
}

.ulol-sec p {
  margin: 24px 0px;
}

ol.ol-alphabet .ol-alphabet {
  padding-left: 98px;
}

ol.ol-alphabet {
  list-style-type: lower-alpha;
  padding-left: 98px;
}

ol.ol-roman {
  list-style-type: upper-roman;
  padding-left: 98px;
}

ol.ol-number {
  list-style-type: upper-gregorian-roman;
  padding-left: 98px;
}

ol.ol-dash {
  list-style-type: "-  ";
  padding-left: 98px;
}

body {
  min-height: 100vh;
  
  
}

.container {
  max-width: 1920px;
  padding: 0px 22px;
  
}

header, footer {
  background: rgba(var(--primary-color),0.5);
  box-shadow: var(--shadow-color);

  
 
  

}

header {
  position: relative;
  padding: 13.5px 32px !important;
}

header .logo-sec {
  display: flex;
  align-items: center;
  gap: 24px;
}

.account-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: max-content;
  height: 40px;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
  border-radius: 16px;
  cursor: pointer;
  padding: 6px 16px 6px 12px;
}

.account-btn:hover {
  box-shadow: var(--shadow-color);
}

.account-name {
  text-transform: uppercase;
  color: var(--font-color);
  margin-bottom: 0%;
}

.menu-option-btn {
  top: 8px !important;
  z-index: 99;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
  border-radius: 8px;
  cursor: pointer;
  --bs-dropdown-min-width: 100%;
}

.menu-option-btn a {
  font-size: 24px;
  line-height: 28px;
  padding: 3px 16px;
  text-decoration: none;
}

.account-btn.dropdown-toggle::after {
  display: none;
}

.menu-option-btn .dropdown-item:hover {
  background-color: rgb(var(--bg-white));
}

.menu-option-btn .dropdown-item {
  border-bottom: 2px solid rgb(var(--border-color));
  padding-bottom: 3px;
}

.menu-option-btn .dropdown-item.logout {
  border-bottom: none;
}

/* section */

.main-section {
  padding: 32px !important;
  padding-bottom: 55px !important;
}

.search-bar {
  position: relative;
  max-width: 800px;
}

.search-bar img {
  position: absolute;
  top: 12px;
  left: 22px;
}

.search-input {
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
  box-shadow: none;
  border-radius: 32px;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: var(--font-color);
  padding-left: 56px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-family: "Syne";
  font-style: normal;
  height: 48px;
}

.search-input:hover,
.search-input:focus {
  box-shadow: 0px 0px 4px rgba(rgb(var(--secondary-color)), 0.25);
  border: 1px solid rgb(var(--secondary-color));
  background: rgba(var(--white-color), 1);
}

::placeholder {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: var(--font-color);
  font-family: "Syne";
  font-style: normal;
}

.options-btn {
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 12px;
  /* gap: 12px; */
  width: 131px;
  height: 40px;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
  border-radius: 16px;
  cursor: pointer;
}

.options-btn:hover {
  box-shadow: var(--shadow-color);
}

.options-btn h3 {
  margin-bottom: 0%;
}

.categories-section {
  margin-top: 32px;
  margin-bottom: 38px;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 135%;
  font-size: 1.8rem;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide .slider-img img {
  width: 56px;
  height: auto;
}

.slick-slide .slider-img {
  cursor: pointer;
  display: block;
  width: 60px;
  height: 60px;
  padding: 10px;
  margin: 3px auto;
  border-radius: 50%;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
}

.slick-slide .slider-img:hover {
  box-shadow: var(--shadow-color);
}

.slide-active .slider-img {
  background: rgb(var(--bg-white));
  border: 1px solid rgb(var(--secondary-color));
}

.slick-slide p {
  text-align: center;
  margin-top: 8px;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slide {
  transition: filter 0.4s;
  margin: 0px 40px;
  cursor: pointer;
}

.fas {
  color: var(--font-color);
}

.slick-prev {
  left: 0;
}

.slick-prev,
.slick-next {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  top: 35%;
  font-size: 1.8rem;
}

.section {
  width: 100%;
  max-width: 1780px;
  margin: 0 auto;
}

.filter-box {
  background: rgb(var(--bg-white));
  border-radius: 16px;
  padding: 24px;
  max-height: 70vh;
  overflow-y: scroll;
  min-width: 195px;
  overflow-x: hidden;
}

.filter-box .clearall {
  text-decoration: underline;
  color: rgb(var(--secondary-color)) !important;
  cursor: pointer;
  text-align: center;
  padding-bottom: 1rem;
}

.filter-box p {
  margin-bottom: 0%;
}

.filter-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.filter-box .label {
  margin-left: 15px;
  cursor: pointer;
}

.filter-box .checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.container-input {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}

.filter-box .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--secondary-color));
  border-radius: 4px;
  transition: all 0.3s;
}

/* When the checkbox is checked, add a blue background */
.filter-box .container-input input:checked~.checkmark {
  background-color: rgb(var(--secondary-color));
  animation: pop 0.5s;
  animation-direction: alternate;
}

/* Create the checkmark/indicator (hidden when not checked) */
.filter-box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.filter-box .container-input input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.filter-box .container-input .checkmark:after {
  left: 7.6px;
  top: 2.6px;
  width: 5.6px;
  height: 11.2px;
  border: solid rgba(var(--white-color), 1);
  border-width: 0 2.4px 2.4px 0;
  transform: rotate(45deg);
}



.notfound p {
  font-weight: 700;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.collapsed .right-arrow {
  transform: rotate(180deg);
}

.navbar-toggler h5 {
  font-family: "Syne";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.mix-card {
  background: rgba(var(--white-color), 1);
  border: 1px solid var(--disabled);
  box-shadow: var(--shadow-color);
  border-radius: 8px;
  padding: 16px;
}

/* .card-container-title {
  position: relative;
  z-index: 9;
  width: fit-content;
  background-color: rgba(var(--white-color), 1);
  margin-bottom: -45px;
  height: 45px;
} */

/* #card-container {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
  padding-top: 50px;
} */

.mix-card p {
  margin-bottom: 15px;
}

.mix-card .card-head {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.mix-card .card-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: var(--font-color);
}

.mix-card .card-title {
  font-weight: 700;
  text-transform: uppercase;
}

.mix-card h3 {
  font-weight: 400;
}

.mix-card .setting-btn,
.mix-card .file-btn,
.mix-card .edit-btn,
.mix-card .delete-btn {
  cursor: pointer;
  position: absolute;
  right: -13px;
  top: -16px;
  z-index: 1;
  width: 40px;
  height: 40px;
  padding: 6px 8px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
  box-shadow: var(--shadow-color);
}

.mix-card .file-btn,
.mix-card .edit-btn,
.mix-card .delete-btn {
  display: none;
}

.mix-card .setting-btn:hover,
.mix-card .file-btn:hover,
.mix-card .edit-btn:hover,
.mix-card .delete-btn:hover {
  box-shadow: none;
}

.mix-card .file-btn {
  right: 32px;
  top: 0px;
}

.mix-card .edit-btn {
  right: 26px;
  top: -47px;
}

.mix-card .delete-btn {
  right: -15px;
  top: -62px;
}

.mix-card .file-btn img,
.mix-card .edit-btn img,
.mix-card .delete-btn img {
  margin-top: -1px;
  margin-left: 2.5px;
}

.filter-position {
  margin-right: 24px;
}

/* footer */

footer h3 {
  font-weight: 400;
}

footer h3 a,
footer h3 a:hover {
  text-decoration: underline;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: rgb(var(--secondary-color)) rgb(var(--white-color));
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--disabled);
  border-radius: 4px;
}

footer {
  position: fixed;
  bottom: 0%;
  z-index: 999999;
}

/* Model  */

.modal-content {
  border-radius: 16px;
  box-shadow: var(--shadow-color);
  background: rgba(var(--white-color), 1);
  border: 1px solid rgba(var(--white-color), 1);
}

#experienceModal .modal-content {
  background: rgb(255, 242, 222);
  border: 1px solid rgb(255, 242, 222);
}

#loginModal .modal-dialog,
#forgotModal .modal-dialog,
#createpassModal .modal-dialog,
#experienceModal .modal-dialog {
  max-width: 484px;
}

#signupModal .modal-dialog {
  max-width: 928px;
}

#loginModal .modal-body,
#experienceModal .modal-body,
#signupModal .modal-body,
#forgotModal .modal-body,
#createpassModal .modal-body {
  padding: 32px;
}

#loginModal h1,
#experienceModal h1,
#signupModal h1,
#forgotModal h1,
#createpassModal h1 {
  color: var(var(--font-color)) !important;
  margin-bottom: 16px;
}

#signupModal .col-md-6 {
  margin-bottom: 32px;
}

#loginModal .model-header-title,
#experienceModal .model-header-title {
  margin-bottom: 16px;
}

#forgotModal .model-header-text,
#signupModal .model-header-text,
#experienceModal .model-header-text,
#createpassModal .model-header-text {
  margin-bottom: 32px;
}

#loginModal input[type="email"] {
  margin-bottom: 24px;
}

#forgotModal input[type="email"],
#createpassModal input[type="password"] {
  margin-bottom: 32px;
}

#loginModal .form-control,
#signupModal .form-control,
#signupModal .form-select,
#forgotModal .form-control,
#createpassModal .form-control {
  padding: 10px 16px;
  border: 1px solid var(--font-color);
  border-radius: 8px;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: var(--font-color);
}

#loginModal .form-control:focus,
#signupModal .form-control:focus,
#signupModal .form-select:focus,
#forgotModal .form-control:focus,
#createpassModal .form-control:focus {
  box-shadow: var(--shadow-color);
}

#loginModal label p,
#signupModal label p,
#forgotModal label p,
#createpassModal label p {
  margin-bottom: 8px;
}

#loginModal ::placeholder,
#signupModal ::placeholder,
#forgotModal ::placeholder,
#createpassModal ::placeholder {
  color: var(--font-color);
}

#loginModal .forgot-password {
  color: rgb(var(--secondary-color)) !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 8px;
  margin-bottom: 24px;
  cursor: pointer;
}

#loginModal .submit-btn,
#signupModal .submit-btn,
#forgotModal .submit-btn,
#createpassModal .submit-btn,
#experienceModal .cancel-btn,
#experienceModal .delete-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  gap: 12px;
  padding: 6px 16px 6px 12px;
  background: rgb(var(--bg-white));
  border: 1px solid rgb(var(--bg-white));
  border-radius: 16px;
}

#experienceModal .col-md-6 {
  padding: 0px 16px;
}

#experienceModal .cancel-btn {
  display: block;
  text-align: center;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
}

#experienceModal .delete-btn {
  display: flex !important;
  background: rgba(var(--accent-caution-color), 1);
}

#loginModal .submit-btn:hover,
#signupModal .submit-btn:hover,
#experienceModal .cancel-btn:hover,
#experienceModal .delete-btn:hover,
#forgotModal .submit-btn:hover,
#createpassModal .submit-btn:hover,
#loginModal .google-btn:hover,
#loginModal .apple-btn:hover,
#loginModal .signup-btn:hover {
  box-shadow: var(--shadow-color);
}

#loginModal .submit-btn p,
#experienceModal .cancel-btn p,
#experienceModal .delete-btn p,
#signupModal .submit-btn p,
#forgotModal .submit-btn p,
#createpassModal .submit-btn p,
#loginModal .google-btn p,
#loginModal .apple-btn p,
#loginModal .signup-btn p {
  margin-bottom: 0%;
  font-weight: 700;
}

#loginModal hr,
#forgotModal hr,
#createpassModal hr {
  border-top: 1px solid rgb(var(--primary-color));
  margin: 32px 0px;
}

#loginModal .google-btn {
  margin-bottom: 16px;
}

#loginModal .google-btn,
#loginModal .signup-btn,
#loginModal .apple-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  gap: 12px;
  padding: 6px 16px 6px 12px;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
  border-radius: 16px;
}

#forpass-modal .modal-dialog {
  min-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  z-index: 99999;
  overflow: hidden;
  margin: 0%;
}

.forpass-card {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forpass-card>div {
  max-width: 955px;
  padding: 46px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.forpass-card img.main {
  width: 136px;
  height: 136px;
}

.forpass-card h1 {
  margin: 32px auto;
  color: rgb(var(--secondary-color));
}

.forpass-card .submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  gap: 12px;
  padding: 6px 16px 6px 12px;
  background: rgb(var(--bg-white));
  border: 1px solid rgb(var(--bg-white));
  border-radius: 16px;
  width: fit-content;
  margin: 32px auto 38px;
}

.forpass-card .submit-btn:hover {
  box-shadow: var(--shadow-color);
}

.forpass-card .submit-btn p {
  margin-bottom: 0%;
  font-weight: 700;
}

.forpass-card a {
  color: var(--link-color);
}

.forpass-card .link {
  margin-bottom: 32px;
}

.forpass-card .p-text {
  margin-bottom: 112px;
}

/* media queery */
@media (min-width: 1199.98px) and (max-width: 1270.98px) {
  .mix-card .card-image {
    max-width: 58px;
    height: auto;
  }

  .filter-position{
    display: block !important;

  }
}

@media (max-width: 991.98px) {
  header .logo-sec {
    gap: 16px;
  }

 
  .account-btn {
    padding: 6px 12px 6px 8px;
    gap: 8px;
    height: 32px;
    border-radius: 12px;
  }

  .search-input {
    border-radius: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    padding-left: 40px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    height: 32px;
  }

  ::placeholder {
    font-size: 18px;
    line-height: 20px;
  }

  .search-bar img {
    top: 7px;
    left: 14px;
    width: 18px;
    height: 17px;
  }

  /* .options-btn {
    width: 94px;
    height: 32px;
    border-radius: 12px;
    padding: 8px 8px 8px 8px;
    gap: 8px;
  } */

  .main-section {
    padding: 32px 24px !important;
  }

  .filter-btn {
    padding: 6px 12px 6px 8px;
    gap: 8px;
    width: fit-content;
    height: 32px;
    margin-right: 16px;
    /* display: none !important; */
  }

   .filter-position {
    position: absolute;
    top: 215px;
    display: block ;
  } 

  .filter-box {
    padding: 16px;
  }

  .checkbox-container {
    margin-bottom: 10px;
  }

  .filter-box .checkmark {
    width: 18px;
    height: 18px;
  }

  .filter-box .container-input .checkmark:after {
    left: 4.6px;
    top: 1.6px;
    width: 5.6px;
    height: 10.2px;
    border: solid rgba(var(--white-color), 1);
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
  }

  .filter-box .label {
    margin-left: 8px;
  }

  .mix-card .setting-btn,
  .mix-card .file-btn,
  .mix-card .edit-btn,
  .mix-card .delete-btn {
    width: 28px;
    height: 28px;
    padding: 3px;
  }

  .mix-card .file-btn img,
  .mix-card .edit-btn img,
  .mix-card .delete-btn img {
    width: 16px;
    height: 16px;
    margin-top: -6px;
    margin-left: 1.5px;
  }

  .mix-card .setting-btn {
    right: -10px;
    top: -12px;
  }

  .mix-card .file-btn {
    right: 24px;
    top: -5px;
  }

  .mix-card .edit-btn {
    right: 17px;
    top: -41px;
  }

  .mix-card .delete-btn {
    right: -19px;
    top: -46px;
  }

  .mix-card .card-head {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .mix-card .card-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .slick-list {
    height: 90px;
  }

  .slide {
    transition: filter 0.4s;
    margin: 0px 14px 0px 22px;
  }

  .menu-option-btn a {
    font-size: 16px;
    line-height: 20px;
    padding: 4px 10px;
  }
/* 
  #card-container {
    padding-top: 39px;
  } */

  #loginModal .modal-dialog,
  #experienceModal .modal-dialog,
  #forgotModal .modal-dialog,
  #createpassModal .modal-dialog {
    max-width: 384px;
  }

  #signupModal .modal-dialog {
    max-width: 728px;
  }

  #loginModal .model-header-text,
  #experienceModal .model-header-text,
  #signupModal .model-header-text,
  #forgotModal .model-header-text,
  #createpassModal .model-header-text {
    margin-bottom: 24px;
  }

  #loginModal hr,
  #forgotModal hr,
  #createpassModal hr {
    margin: 24px 0px;
  }

  #loginModal .form-control,
  #signupModal .form-control,
  #forgotModal .form-control,
  #createpassModal .form-control {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }

  .forpass-card img.main {
    width: 96px;
    height: 96px;
  }
}

@media (max-width: 768.98px) {
  .slide {
    transition: filter 0.4s;
    margin: 0px 6px 0px 13px;
  }
/* 
  .options-btn{
    position: absolute !important;
    top: 18% !important;
    left: 25% !important;
  } */

  .slick-prev,
  .slick-next {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  /* .card-container-title {
    margin-bottom: -51px;
    height: 52px;
  } */

 
  
  .options-btn {
    position: absolute !important;
    top: -5.8rem !important;
    left: 0% !important;
}
  .custom-filter-btn {
      transform: translateY(-112%);
}
  header .logo-sec {
    gap: 8px;
  }

  .slick-prev,
  .slick-next {
    font-size: 16px;
  }

  .menu-option-btn a {
    padding: 3px 4px;
  }

  .slick-prev,
  .slick-next {
    top: 28%;
  }

  .slick-list {
    height: 82px;
  }

  .slide {
    transition: filter 0.4s;
    margin: 0px 30px 0px 4px;
  }

  .logo-image {
    width: 100px;
  }

  .account-btn {
    padding: 6px;
    height: 24px;
    gap: 4px;
    border-radius: 8px;
  }


  .filter-box{
      position: absolute;
      top: 15px;
      left: -7px;
      
  }
  .account-btn img {
    width: 12px;
  }

  .search-input {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-left: 34px;
    padding-right: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 24px;
  }

  ::placeholder {
    font-size: 14px;
    line-height: 16px;
  }

  .search-bar img {
    top: 5px;
    left: 12px;
    width: 14px;
    height: 14px;
  }

  /* .options-btn {
    width: 71px;
    height: 24px;
    border-radius: 8px;
    padding: 4px 8px 4px 8px;
    gap: 4px;
  } */

  .options-btn img {
    width: 15px;
    height: 15px;
  }

  .main-section {
    padding: 24px !important;
  }

  .filter-btn {
    padding: 4px 8px;
    gap: 8px;
    width: fit-content;
    height: 24px;
    margin-right: 16px;
  }

  .filter-position {
    position: absolute;
    top: 160px;
  }

  .filter-box {
    padding: 12px 8px 12px 12px;
    min-width: 150px;
    max-width: 354px;
    transform: translateY(-10px);
  }

  .checkbox-container {
    margin-bottom: 14px;
  }

  .filter-box .checkmark {
    width: 14px;
    height: 14px;
  }

  .filter-box .container-input .checkmark:after {
    left: 3.6px;
    top: 0.6px;
    width: 3.6px;
    height: 8.2px;
    border: solid rgba(var(--white-color), 1);
    border-width: 0px 1.8px 1.8px 0;
    transform: rotate(45deg);
  }

  .mix-card .card-image {
    max-width: 98px;
    height: auto;
  }

  .mix-card .setting-btn,
  .mix-card .file-btn,
  .mix-card .edit-btn,
  .mix-card .delete-btn {
    width: 24px;
    height: 24px;
    padding: 4px;
  }
  .mix-card .file-btn img,
  .mix-card .edit-btn img,
  .mix-card .delete-btn img {
    width: 13px;
    height: 13px;
    margin-top: -12px;
    margin-left: 0.5px;
  }

  .mix-card .setting-btn {
    right: -10px;
    top: -12px;
  }

  .mix-card .file-btn {
    right: 16px;
    top: -5px;
  }

  .mix-card .edit-btn {
    right: 11px;
    top: -33px;
  }

  .mix-card .delete-btn {
    right: -15px;
    top: -40px;
  }

  .mix-card .card-head {
    font-size: 12px;
    line-height: 20px;
  }

  .mix-card .card-text {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }

  .filter h2 {
    font-size: 20px;
    line-height: 29px;
    padding-right: 5px;
  }

  .search-bar {
    padding-left: 0%;
  }

  .slick-slide .slider-img {
    width: 48px;
    height: 48px;
    padding: 9px;
  }

  .notfound img {
    width: 24px;
  }

  .categories-section {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  #loginModal .modal-body,
  #experienceModal .modal-body,
  #signupModal .modal-body,
  #forgotModal .modal-body,
  #createpassModal .modal-body {
    padding: 24px;
  }

  #loginModal .model-header-text,
  #experienceModal .model-header-text,
  #signupModal .model-header-text,
  #forgotModal .model-header-text,
  #createpassModal .model-header-text {
    margin-bottom: 16px;
  }

  #loginModal hr,
  #forgotModal hr,
  #createpassModal hr {
    margin: 16px 0px;
  }

  #loginModal .form-control,
  #signupModal .form-control,
  #signupModal .form-select,
  #forgotModal .form-control,
  #createpassModal .form-control {
    padding: 6px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  #loginModal .forgot-password {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  #loginModal .modal-dialog,
  #experienceModal .modal-dialog,
  #forgotModal .modal-dialog,
  #createpassModal .modal-dialog,
  #signupModal .modal-dialog {
    max-width: 296px;
    margin: auto;
  }

  #experienceModal .delete-btn {
    padding: 4px 8px;
    gap: 4px;
  }

  .forpass-card h1 {
    margin: 24px auto;
  }

  .forpass-card .submit-btn {
    margin: 24px auto;
  }

  .forpass-card .link {
    margin-bottom: 24px;
  }

  .forpass-card .p-text {
    margin-bottom: 80px;
  }

  .forpass-card img.main {
    width: 80px;
    height: 80px;
  }
}

/* Home page section  */

#homepage header .main-img {
  width: 256px;
  height: 271px;
}

#homepage header .header-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  margin-bottom: 24px;
}

#homepage header.without-image .header-title {
  justify-content: center;
  margin-bottom: 48px;
}

.all-btn {
  position: relative;
  display: inline-block;
  width: 200px;
}

.main-btn:hover,
.all-btn .book-btn:hover,
.all-btn .pen-btn:hover,
.all-btn .home-btn:hover,
.all-btn .plus-btn:hover {
  box-shadow: none;
}

.main-btn {
  cursor: pointer;
  margin: auto;
  width: 64px;
  height: 64px;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
  border-radius: 16px 24px 50px;
  transform: rotate(45deg);
  box-shadow: var(--shadow-color);
}

.main-btn img {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  transform: rotate(-45deg);
}

.all-btn .book-btn,
.all-btn .pen-btn,
.all-btn .home-btn,
.all-btn .plus-btn {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  padding: 4px 8px;
  display: none;
  border-radius: 50%;
  justify-content: center;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
  box-shadow: var(--shadow-color);
}

.all-btn .book-btn {
  right: 16px;
  top: -6px;
}

.all-btn .pen-btn {
  right: 29px;
  top: 50px;
}

.all-btn .home-btn {
  left: 16px;
  top: -5px;
}

.all-btn .plus-btn {
  right: 79px;
  top: 69px;
}

#homepage header {
  padding: 20px 60px 80px !important;
}

#homepage header h3 {
  text-transform: uppercase;
}

#homepage header p {
  margin-bottom: 0%;
}

header a {
  color: var(--link-color);
  text-decoration-line: underline;
}

.main-sec {
  padding: 80px 176px;
  background: rgba(var(--primary-color), 0.1);
}

.sec {
  margin-bottom: 36px;
}

.sec h2 {
  color: var(--font-color);
}

.sec img {
  width: 24px;
  height: 28px;
  margin-right: 16px;
}

.sec p {
  color: var(--font-color) !important;
  padding-left: 40px;
  padding-right: 40px;
  text-align: justify;
}

.sec ul {
  list-style-type: square;
  padding-left: 68px;
  color: var(--font-color);
}

.sec ul li {
  color: var(--font-color);
}

.pic-sec .image-card {
  width: 360px;
  border: 0.5px solid rgb(var(--primary-color));
  border-radius: 16px;
  margin-bottom: 40px;
}

.pic-sec .image-video-card {
  width: 1060px;
  border: 0.5px solid rgb(var(--primary-color));
  border-radius: 16px;
  margin-bottom: 40px;
}

.pic-sec .image-video-card video,
.pic-sec .image-video-card iframe {
  width: 100%;
  min-height: 560px;
  border: 0.5px solid rgb(var(--primary-color));
  border-radius: 16px;
  object-fit: cover;
}

.pic-sec .image-card img,
.pic-sec .image-card .player {
  width: 100%;
  height: 100%;
  border: 0.5px solid rgb(var(--primary-color));
  border-radius: 16px;
}

.pic-sec .image-video-card .card-body {
  background-color: rgba(var(--white-color), 1);
  padding: 6.5px 10px 14.5px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.pic-sec .image-card .card-body {
  background-color: rgba(var(--white-color), 1);
  padding: 6.5px 10px 14.5px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.compo-sec .card {
  width: 368px;
  height: 250px;
  background: rgb(var(--bg-white));
  border: 1px solid rgb(var(--secondary-color));
  box-shadow: var(--shadow-color);
  border-radius: 24px;
  padding: 24px 24px 32px;
  margin-bottom: 40px;
}

.compo-sec .card .image-block {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* flip-card */
.flip-card {
  cursor: pointer;
  perspective: 1000px;
  width: 368px;
  height: 250px;
  background: transparent;
  margin-bottom: 40px;
}

.flip-card h3 {
  margin-bottom: 24px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 1rem;
  background-color: rgb(var(--bg-white));
  border: 1px solid rgb(var(--secondary-color));
  box-shadow: var(--shadow-color);
  border-radius: 24px;
  padding: 24px 24px 32px;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.citactions-sec,
.citactions-border-sec {
  margin-bottom: 40px;
}

.citactions-sec.homepage>div,
.citactions-text-sec.homepage>div {
  width: 343px;
}

.citactions-sec>div,
.citactions-text-sec>div {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 8px 24px;
  gap: 16px;
  background: rgb(var(--bg-white));
  border-top: 0.5px solid rgb(var(--secondary-color));
  border-bottom: 0.5px solid rgb(var(--secondary-color));
}

.citactions-sec.homepage h3,
.citactions-text-sec.homepage h3 {
  width: 343px;
}

.citactions-sec h3,
.citactions-text-sec h3 {
  width: 100%;
  margin: auto;
  font-weight: 600;
  padding: 6px 16px 8px 24px;
  color: rgb(var(--secondary-color));
}

.citactions-sec.do>div,
.citactions-text-sec.do>div {
  background: rgba(var(--accent-do-color), 0.1);
  border-top: 0.5px solid rgba(var(--accent-do-color), 1);
  border-bottom: 0.5px solid rgba(var(--accent-do-color), 1);
}

.citactions-sec.do h3,
.citactions-text-sec.do h3 {
  color: rgba(var(--accent-do-color), 1);
}


.citactions-sec.caution>div,
.citactions-text-sec.caution>div {
  background: rgba(255, 177, 61, 0.3);
  border-top: 0.5px solid rgba(var(--accent-caution-color), 1);
  border-bottom: 0.5px solid rgba(var(--accent-caution-color), 1);
}

.citactions-sec.caution h3,
.citactions-text-sec.caution h3 {
  color: rgba(var(--accent-caution-color), 1);
}

.citactions-sec.do-not>div,
.citactions-text-sec.do-not>div {
  background: rgba(var(--accent-dont-color), 0.1);
  border-top: 0.5px solid rgba(var(--accent-dont-color), 1);
  border-bottom: 0.5px solid rgba(var(--accent-dont-color), 1);
}

.citactions-sec.do-not h3,
.citactions-text-sec.do-not h3 {
  color: rgba(var(--accent-dont-color), 1);
}

/* citactions-border-sec */
.citactions-border-sec.homepage .card {
  width: 343px;
}

.citactions-border-sec .card {
  width: 100%;
  margin: auto;
  border-radius: 16px;
  border: 0.5px solid rgb(var(--secondary-color));
}

.citactions-border-sec .card .card-head {
  display: flex;
  align-items: center;
  background: rgb(var(--bg-white));
  gap: 8px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: 0.5px solid rgb(var(--secondary-color));
  padding: 12px 10px;
}

.citactions-border-sec .card .card-section {
  background: rgba(var(--white-color), 1);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 10px 16px 10px 10px;
}

.citactions-border-sec .card .card-head h3 {
  text-transform: uppercase;
  color: rgb(var(--secondary-color));
}

.citactions-border-sec .card p {
  margin-bottom: 0%;
}

.citactions-border-sec.do .card {
  border: 0.5px solid rgba(var(--accent-do-color), 1);
}

.citactions-border-sec.do .card .card-head {
  background: rgba(var(--accent-do-color), 0.1);
  border-bottom: 0.5px solid rgba(var(--accent-do-color), 1);
}

.citactions-border-sec.do .card .card-head h3 {
  color: rgba(var(--accent-do-color), 1);
}

.citactions-border-sec.do-not .card {
  border: 0.5px solid rgba(var(--accent-dont-color), 1);
}

.citactions-border-sec.do-not .card .card-head {
  background: rgba(var(--accent-dont-color), 0.1);
  border-bottom: 0.5px solid rgba(var(--accent-dont-color), 1);
}

.citactions-border-sec.do-not .card .card-head h3 {
  color: rgba(var(--accent-dont-color), 1);
}

.citactions-border-sec.caution .card {
  border: 0.5px solid rgba(var(--accent-caution-color), 1);
}

.citactions-border-sec.caution .card .card-head {
  background: rgb(255, 242, 222);
  border-bottom: 0.5px solid rgba(var(--accent-caution-color), 1);
}

.citactions-border-sec.caution .card .card-head h3 {
  color: rgba(var(--accent-caution-color), 1);
}

/* table  */

.table-sec th>h3,
.table-sec p {
  margin-bottom: 0px;
  color: var(--font-color) !important;
}

.table-sec thead tr,
.table-sec tfoot tr {
  background: rgb(var(--bg-white));
  border-top: 0.5px solid rgb(var(--secondary-color));
  border-bottom: 0.5px solid rgb(var(--secondary-color));
}

.table-sec thead tr th {
  padding: 16px;
  text-transform: uppercase;
}

.table-sec tbody td {
  background: rgba(var(--white-color), 1);
  padding: 16px;
  border-bottom: 0.5px solid rgb(var(--secondary-color));
}

.position-relative.overflow-scroll::-webkit-scrollbar:horizontal {
  width: 8px;
  height: 8px;
}

figure {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 40px;
  max-width: 360px;
}

#av-tag {
  display: none;
}

.player {
  background: rgba(var(--secondary-color), 0.1);
  width: 343px;
  padding: 8px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.toggle-play {
  cursor: pointer;
  width: 24px;
  height: 28px;
  padding-right: 30px;
  background-repeat: no-repeat;
}

.toggle-play.play {
  background-image: url("../images/play.svg");
}

.toggle-play.pause {
  background-image: url("../images/pause.svg");
}

.toggle-play.play:hover {
  transform: scale(1.1);
}

.toggle-play.pause:hover {
  transform: scale(1.1);
}

.time {
  display: flex;
  gap: 3px;
  margin: 0px 8px 0px 14px;
}

.time p {
  color: var(--font-color) !important;
  margin-bottom: 0%;
}

.timeline {
  background: rgba(var(--white-color), 1);
  border: 0.5px solid var(--font-color);
  width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  margin: 0px 10px;
}

.timeline .progress {
  background: rgba(var(--black-color), 0.2);
  width: 0%;
  height: 5px;
  transition: 0.25s;
}

.volume-button {
  cursor: pointer;
  width: 24px;
  height: 28px;
  padding-right: 20px;
  background-repeat: no-repeat;
}

.volume-button.volume {
  background-image: url("../images/volume.svg");
}

.volume-button.muted {
  background-image: url("../images/muted.svg");
}

@media (max-width: 1199.98px) {

  .container,
  .main-sec {
    padding: 80px 122px;
  }


  
  /* .options-btn{
    position: absolute !important;
    top: 18% !important;
    left: 25% !important;
  } */

  .pic-sec .image-video-card {
    width: 760px;
  }
}

@media (max-width: 991.98px) {
  .pic-sec .image-video-card {
    width: 430px;
  }
  #homepage header {
    padding: 0px !important;
  }


  
  /* .options-btn{
    position: absolute !important;
    top: 18% !important;
    left: 25% !important;
  } */


  .pic-sec .image-video-card video,
  .pic-sec .image-video-card iframe {
    width: 100%;
    min-height: 260px;
  }

  .container {
    padding: 32px 32px;
  }



  #homepage header .main-img {
    width: 148px;
    height: 157px;
  }

  .all-btn img {
    width: 16px;
    height: 16px;
    margin-top: -9px;
  }

  .main-btn {
    width: 41px;
    height: 41px;
  }

  .main-btn img {
    margin-top: 3px;
    width: 18px;
    height: 28px;
    transform: rotate(-45deg);
  }

  .all-btn .book-btn,
  .all-btn .pen-btn,
  .all-btn .home-btn,
  .all-btn .plus-btn {
    width: 33px;
    height: 33px;
  }

  .all-btn .book-btn {
    right: 32px;
    top: -6px;
  }

  .all-btn .pen-btn {
    right: 45px;
    top: 34px;
  }

  .all-btn .home-btn {
    left: 32px;
    top: -2px;
  }

  .all-btn .plus-btn {
    right: 83px;
    top: 52px;
  }

  .main-sec {
    padding: 80px 32px;
  }

  .pic-sec .image-card {
    max-width: 100%;
  }

  .flip-card {
    width: 308px;
    height: 180px;
  }
}

@media (max-width: 575.98px) {
  #homepage header {
    padding: 0px !important;
  }

/*   
  .options-btn{
    position: absolute !important;
    top: 18% !important;
    left: 25% !important;
  } */


  .pic-sec .image-video-card {
    width: 100%;
    max-width: 358px;
  }

  .pic-sec .image-video-card video,
  .pic-sec .image-video-card iframe {
    width: 100%;
    min-height: 100%;
  }

  #homepage header .main-img {
    max-width: 87px;
    max-height: 93px;
  } 
   #homepage header {
    padding: 0px !important;
  }

  .container {
    padding: 24px 24px;
  }

  .main-sec {
    padding: 80px 24px !important;
  }

  ol,
  ul {
    padding-left: 48px;
    margin-bottom: 24px;
  }

  .ulol-sec p {
    padding-left: 27px !important;
    margin: 24px 0px;
  }

  ol.ol-alphabet .ol-alphabet {
    padding-left: 40px;
  }

  .sec p,
  .sec ul {
    color: var(--font-color) !important;
    padding-left: 40px;
    padding-right: 40px;
  }

  .all-btn img {
    width: 16px;
    height: 16px;
    margin-top: -12px;
  }

  .main-btn {
    width: 24px;
    height: 24px;
  }

  .main-btn img {
    margin-top: -8px;
    width: 16px;
    height: 19px;
    transform: rotate(-45deg);
  }

  .all-btn .book-btn,
  .all-btn .pen-btn,
  .all-btn .home-btn,
  .all-btn .plus-btn {
    width: 22px;
    height: 22px;
    padding: 2px;
  }

  .all-btn .book-btn {
    right: 58px;
    top: -7px;
  }

  .all-btn .pen-btn {
    right: 61px;
    top: 20px;
  }

  .all-btn .home-btn {
    left: 57px;
    top: -2px;
  }

  .all-btn .plus-btn {
    right: 90px;
    top: 31px;
  }

  .main-sec {
    padding: 32px;
  }

  .flip-card {
    width: 258px;
    height: 160px;
  }

  .citactions-border-sec .card,
  .citactions-sec>div,
  .citactions-text-sec>div {
    width: 100%;
  }

  video,
  .player {
    width: 100%;
  }
}








.custom-filter-btn{
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 12px;
  gap: 12px;
  width: 131px;
  height: 40px;
  background: rgba(var(--white-color), 1);
  border: 1px solid rgb(var(--border-color));
  border-radius: 16px;
  cursor: pointer;
  position: absolute !important;
  top: 10.4rem !important;
}

 @media only screen and (max-width: 575px) {
  .options-btn{
   transform: translate(115%,410%) !important;
   height: 24px !important;
   padding-inline: 6px !important;
   width:73px !important;
   margin-left:50px !important;
  }
  
  #homepage header {
    padding: 0px !important;
  }
  .filter-box{
    position: absolute !important;
    top:"50%";
    width:"430px " !important;
    padding-inline: 6px !important;
  }
 }






  



 
@media (max-width: 399.98px) {
  .slide {
    transition: filter 0.4s;
    margin: 0px 12px 0px 7px;
  }

  
  .filter-box{
    position: absolute;
    top: 6px;
    
}
 
  .custom-filter-btn {
    transform: translateY(-0%);
    
}


  #faRight, #faLeft{
        transform: scale(0.70) !important;
  }


  .filter-box .clearall {
    text-decoration: underline;
    color: rgb(var(--secondary-color)) !important;
    cursor: pointer;
    text-align: center;
    padding-bottom: 0rem;
    font-size: 16px !important;
}

  .custom-filter-btn{
    position: absolute !important;
    top: 8rem !important;
    height: 24px !important;
  }

  
  .options-btn{
    position: absolute !important;
    top: -6.5rem !important;
    left: 0rem !important;
    height: 24px !important;
    width: 71px !important;
  }


  .slick-prev,
  .slick-next {
    top: 29%;
  }

  .upload-btn{
    width:200px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.titleSub{
  font-size: 14px !important;
  font-weight: 600 !important;
}

.hRule{
  display:  none !important;
}

.titleInstances{
 width: 180px !important;
 margin-left: 30%;
}

.listItems{
  margin-left: 6rem !important;
}

.listItemsIcons{
  margin-right: 9rem !important;
}
}
#homepage header {
  padding: 0px !important;
}

