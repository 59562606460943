.code-editor-style .codex-editor__redactor {
    padding-bottom: 0px !important;
}

img {
    width: 100%;
}

.image-style>img {
    width: 100%;
}

.code-editor-style{
    font-family: "Syne";
}
.code-editor-style .ce-block{
  margin-block: 10px;
    
}
/* .code-editor-style .ce-block .ce-block__content{
  border: 2px solid red;
  min-width: 90%;
  padding-block: 5px;
}    */
/* .code-editor-style .ce-toolbar__actions{
    border: 2px solid green;

} */

/* ////////// custom table /////////// */

.code-editor-style .tc-cell{
    font-family: "Syne";
    font-style: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
}   

/* ///////////image part ///////////// */
.image-tool__image-picture,
.code-editor-style .inline-image__picture img{
    min-width: 200px ;
    min-height:200px ;
}   
/* /////////////////custom paragrapg/////////////// */
.code-editor-style .ce-paragraph
{
    /* color: var(--font-color) !important;
    padding-left: 40px;
    padding-right: 40px;
    text-align: justify; */

    font-family: "Syne";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: justify;
    color: var(--font-color);
}
@media (max-width: 991.98px){
    .code-editor-style .ce-paragraph {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    .code-editor-style .tc-cell {
    font-size: 14px;
    line-height: 20px;
    }

    /* //card /// */
    /* .custom-card-tool textarea  {
        height:50px;
     
    } */
}
/* ///////////fix math tex///////// */
.code-editor-style .katex-html{
    padding: 10px;
}

/* ////////custom card////////////// */
.custom-card-tool textarea  {
  height:100%;
  text-align: justify;
  resize: none;
  border: none;
}
.custom-card-tool input  {
   text-align: center;
   border: none;
 }

 .ce-block__content, .ce-toolbar__content { max-width:calc(100% - 80px) !important; } .cdx-block { max-width: 100% !important; }

 .code-editor-style ::placeholder{
    color: grey
 }


 /* /////mermaid ///////// */
 .cdx-input .mermaid-input{
    font-family: "Syne";
 }
/* ///// model table //////// */
.model-table-data-help-cover ::placeholder{
    font-size: medium;
}


/* ///////header with logo part  */
#header-tool-container h1,#header-tool-container h2,#header-tool-container h3{
    min-width: 100%;
    padding-block: 5px;
    padding-inline: 4px;
    height: auto;
    outline: 0px solid transparent;
}




#header-begin-logo{
    
    
}

/* ////////style table///////// */
.tc-row{
    background-color: white;
}
.tc-row [heading="Heading"]{
    background-color:rgb(249, 238, 248)

}
.tc-row .tc-cell{
    padding: 10px;
}

/* /////custom audio tool////// */

.custom-audio-tool-container ::placeholder{
    font-size:medium
}
.custom-audio-tool-container{
    
    
}
.ce-rawtool__textarea {
   color: #4AFA26 !important
}