.experienc_titleField {
    padding: 4px 6px;
    border-radius: 8px;
    min-width: 60vw;
    color: rgba(41, 41, 41, 1);
    background: transparent;
    /* font-weight: 600; */ 
    outline: none;
    border: 0.2px solid #802376;

}
#homepage header{
    max-width: 1920px;
}
.experienc_congnitive_Field {
    min-width: 150px;
    padding: 6px;
    border-radius: 8px;
    font-size: 18px;
    background: transparent;
    font-weight: 500;
    outline: none;
    border: 0.2px solid #802376;
}

.experienc_subject_field_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.experienc_background_area {
    min-height: 100px;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    background: transparent;
    outline: none;
    border: 0.2px solid #802376;
}

.add_edit_btn {
    cursor: pointer;
    width: 120px;
    text-transform: capitalize;
    gap: 12px;
    padding: 4px;
    background: rgb(var(--bg-white));
    border: 1px solid rgb(var(--bg-white));
    border-radius: 16px;
}

.add_edit_btn:hover {
    box-shadow: var(--shadow-color);
}   

.exp_header{
    padding: 20px 80px 26px ;
  }

  /* /////for view ////////// */
 /* .header-title{
    width: 300px !important;
 } */





  /* /////end view ////////// */



/* /////for mobile////// */
@media (min-width:200px) and (max-width:576px) {
    /* .experienc_titleField {
       font-size:medium;
    } */
    .experienc_congnitive_Field{
        font-size: small; 
        min-width: 100px !important;
        max-width: 100px !important;
    } .exp_header{
        padding: 20px 24px 26px;
      }
          
}
/* //////for tab ////// */
@media (min-width:576px) and (max-width:768px) {
    /* .experienc_titleField {
       font-size: medium;
    } */
    .experienc_congnitive_Field{
        font-size: medium;
    }
    .exp_header{
        padding: 20px 80px 26px ;
        
      }
}

/* //////for large ////// */
@media (min-width:768px) and (max-width:992px) {
    /* .experienc_titleField {
       font-size:large;
    } */
    .experienc_congnitive_Field{
        font-size:large;
    }
    .exp_header{
        padding: 20px 80px 26px ;
      }
}

/* //////background section/////// */
.bg-section-container{
    word-wrap:break-word;
    position: relative;
    z-index: 2 !important;
 }
 .goal-section-container{
     word-wrap:break-word;
 }

/* /////////for debug/////////// */
.bg-section-container .ce-popover{
    
}
.goal-section-container .cdx-block{
    
}
.goal-section-container .ce-paragraph[data-placeholder]:empty::before{
}

.bg-section-container .ce-block{
    position: relative;
    z-index: 0;
}

/* .img-exp-container{
max-width: fit-content;
margin-inline: auto;
border-radius: 16px;
min-height :35vh;
border: 2px solid red;
}
.img-exp-bg{c
    min-width: min-content;
    min-height :30vh;
    border-radius: 16px;
} */


/* /////////////////custom audio player///////////////// */
.audio-mute-line {
    position: relative;
    top: 1px;
    left: 7px;
    background-color: red;
    height: 28px;
    width: 2px;
    transform: rotate(-45deg);
    border: 0.5px solid red;
}                   


input[type='range'] {
    /* overflow: hidden; */
    width: 85px;
    /* -webkit-appearance: none; */
    border-radius: 16px;
    height: 5px;
    background-color: white
  }

  input[type='range']::-webkit-slider-thumb {
    /* -webkit-appearance: none; */
    height: 10px;
    width: 10px;
    border-radius: 16px;
    background: #434343;
  
  }
  .custom-audio-container ::-webkit-media-controls-enclosure {
    border-radius: 5px;
    background-color:rgba(var(--secondary-color), 0.13);
}

#mermaid svg{
    width:100vw;
}


/* header input component */

#header-tool-container ::placeholder{
    font-size: inherit !important;
    font-family: Suez One !important;
    line-height: 45px;
}
#header-tool-text{
    width: 20vw;
    word-wrap: break-word;
    max-width: fit-content; 
    margin-block: auto;
    border: none;
    background-color: inherit;
}

.custom-inline-image-component-container{
    font-size: small;
}
.custom-inline-image-component-container ::placeholder{
    font-size: small;
}
.custom-inline-image-view{
    width: 300px !important;
    height: 300px !important;
    border-radius: 16px;
    border: 1px solid orange;
}

/* ///////model table ///////// */
.model-table-row{
 
}
.model-add-remove-button{
    cursor: pointer;
    max-height: 15px;
    max-width: 15px;
    margin-inline: auto;
    margin-block: 5px;
}
.model-add-remove-button:hover{
  transform: scale(1.3);
}


/* /////Quote box success bg color/// */
.citactions-sec.do>div, .citactions-text-sec.do>div, .citactions-border-sec.do .card .card-head{
    background-color: rgba(var(--accent-do-color), 0.1)!important;
    opacity: 1;
}

/* ///warning color///// */
.citactions-sec.caution>div, .citactions-text-sec.caution>div{
    background: rgb(255, 242, 222) !important;
}

p a,table a{
    text-decoration: underline ;
}

.bg-section-data .card-body p{
    padding-left: 0px;
}

.goal-section-container .ce-paragraph,.bg-section-container .ce-paragraph{
    padding-left: 44px;
}

.goal-section-container,.bg-section-container{
 
}

/* ///output data///// */
.bg-section-data{
   
}
.goal-section-data{

}